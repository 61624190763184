export const canNavigate = (to, userData) => {
  const role = userData ? userData.role : null
  const roleLevel = userData ? userData.roleLevel : null

  if (!role || !roleLevel || roleLevel < 6) return false

  return to.matched.some(route => !route.meta.roleLevel || roleLevel >= route.meta.roleLevel)
}

export const _ = undefined
