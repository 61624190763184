export const useResponseRouting = (axiosIns, router) => {
  axiosIns.interceptors.response.use(
    // response => Promise.resolve(response),
    response => response,
    error => {
      const { response: { status, data } } = error

      if (status === 401 && data.code === 409) {
        // 커스텀 에러 409: 동시 접속으로 인한 기존 계정 차단 처리
        alert(data.message)
        localStorage.removeItem('userData')
        router.replace({ name: 'auth-login' })
      } if (status === 401 || status === 405) {
        axiosIns.get('/fa/auth/is-login').then(isLoginRes => {
          if (!isLoginRes || isLoginRes.isLogin === false) {
            // 로그인 페이지로 이동
            localStorage.removeItem('userData')
            router.replace({ name: 'auth-login' })
          }
        })
      }
      // eslint-disable-next-line consistent-return
      return Promise.reject(error)
    },
  )
}

export const test = () => { }
