import axios from '@axios'

/**
 * Return if user is logged in
 * This is completely up to you and how you want to store the token in your frontend application
 * e.g. If you are using cookies to store the application please update this function
 */
// eslint-disable-next-line arrow-body-style
export const isUserLoggedIn = async (isClient = false) => {
  try {
    const rs = isClient ? await axios.get('/cl/client/is-login') : await axios.get('/fa/auth/is-login')

    return (rs.data && rs.data.isLogin && localStorage.getItem('userData'))
  } catch (err) {
    return false
  }
}

export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

export const clearUserData = () => localStorage.removeItem('userData')

/**
 * This function is used for demo purpose route navigation
 * In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 * Please note role field is just for showing purpose it's not used by anything in frontend
 * We are checking role just for ease
 * NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = userRole => {
  if (userRole === 'Manager' || userRole === 'SuperAdmin' || userRole === 'SystemAdmin') return '/'
  return { name: 'auth-login' }
}

export const getInternal = async () => {
  const result = await axios.get('/fa/auth/internal')
  return result.data
}

export const getHomeRouteForLoggedInClient = userRole => {
  if (userRole === 'Client') return { name: 'clients-home' }
  return { name: 'client-login' }
}

export const isClientRole = () => {
  const userData = getUserData()
  return (userData && userData.role === 'Client')
}
